export const Config = {
  //! @note Adjust project name, domains and URLs accordingly.
  'productName': 'cron-job.org OSS',
  'baseURL': 'https://spacehub.mn',
  'twitterURL': '6LepHcEiAAAAACSATbezAG17KJ5Bt5hcPnxjCDe_',
  'githubURL': 'https://github.com/spacehub-mn',
  'privacyPolicyURL': 'https://spacehub.mn',
  'termsOfServiceURL': 'https://spacehub.mn',
  'returnPolicyURL': 'https://spacehub.mn',
  'apiDocsURL': 'https://spacehub.mn',

  //! @note The URL of the API webserver (/api/ folder in this repo)
  'apiURL': 'https://api.cron.spacehub.mn/',

  //! @note Put your reCAPTCHA site key here.
  'recaptchaSiteKey': '6LcYctYiAAAAAPiMhTq--t-0kJDC9Wtzbv2ef730',

  /****************************************************************************************
   ******** The following settings might be kept as is. Customization is optional. ********
   ****************************************************************************************/

  //! @note List of supported languages. When adding new language file, be sure
  //!       to add the new language code here.
  'languages': {
    'en': 'English',
    'de': 'Deutsch',
    'it': 'Italiano',
    'fr': 'Français'
  },

  //! @note Fallback language to use when the user's auto-determined language is not supported.
  'fallbackLanguage': 'en',

  //! @note Session token refresh interval in ms.
  'sessionRefreshInterval': 10 * 60 * 1000,

  //! @note A donation box can be enabled here. It will be shown when the user exceeds the
  //!       configured successful job count threshold.
  'donationBox': {
    'enable': false,
    'successfulJobsThreshold': 1,
    'hostedButtonId': 'PLACE_PAYPAL_HOSTED_BUTTON_ID_HERE'
  },

  //! @note If you want to offer a membership, define the amounts here.
  'sustainingMembership': {
    'enable': false,
    'amountsAMonth': [5, 10, 20],
    'amountsAYear': [12, 24, 36],
    'box': {
      'enable': false,
      'successfulJobsThreshold': 1
    }
  },

  //! @note Status pages support can be optionally enabled here.
  'enableStatusPages': true,
  'statusPageDomain': 'status.cron.spacehub.mn',
  'maxLogoSize': 1024 * 128,
};
